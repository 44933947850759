export const employementTypeOptions = [
  { id: 1, label: "FULL_TIME", value: "full_time" },
  { id: 2, label: "PART_TIME", value: "part_time" },
  { id: 3, label: "HOURLY_TIME", value: "hourly" },
  { id: 4, label: "SHIFTS", value: "shifts" }
]
export const getEmploymenTypeLabel = (employment_type_value: string) => {
  const employementTypeObject = employementTypeOptions.find(o => o.value === employment_type_value)

  if (employementTypeObject) {
    return employementTypeObject.label
  }

  return employment_type_value
}

export const jobTypeOptions = [
  { id: 1, label: "OFFICE", value: "on_site" },
  { id: 2, label: "REMOTE", value: "remote" },
  { id: 3, label: "HYBRID", value: "hybrid" },
  { id: 4, label: "FREE_SCHEDULE", value: "freelance" }
]
export const getJobTypeLabel = (job_type_value: string) => {
  const jobTypeObject = jobTypeOptions.find(o => o.value === job_type_value)

  if (jobTypeObject) {
    return jobTypeObject.label
  }

  return job_type_value
}

export const hireTypeOptions = [
  { id: 1, label: "URGENT", value: "urgent" },
  { id: 2, label: "CONTRACT", value: "contract" },
  { id: 3, label: "INTERNSHIP", value: "intern" },
  { id: 4, label: "SEASONAL", value: "seasonal" },
  { id: 5, label: "FREELANCE", value: "freelance" }
]

export const getHireTypeLabel = (hire_type_value: string) => {
  const hireTypeObject = hireTypeOptions.find(o => o.value === hire_type_value)

  if (hireTypeObject) {
    return hireTypeObject.label
  }

  return hire_type_value
}
