import { useQuery } from "@tanstack/react-query"
import { ACCESS_TOKEN_NAME } from "src/env"
import { TNotification } from "src/types/notificationTypes"
import Cookie from "src/utils/configs/Cookie"
import NotificationsService from "../services/NotificationsService"
import { QUERY_KEYS } from "./queryKeys"
import { useRouter } from "next/router"

const useNotifications = () => {
  const router = useRouter()
  const subscriptionsQuery = useQuery({
    queryKey: [QUERY_KEYS.GET_SUBSCRIPTIONS],
    queryFn: () => getSubscriptions(),
    enabled: !!Cookie.get(ACCESS_TOKEN_NAME),
    staleTime: 1000 * 60 * 3, // 3 minutes
    cacheTime: 1000 * 60 * 3 // 3 minutes
  })

  const notificationsQuery = useQuery<{
    current_page: number
    last_page: number
    per_page: number
    data: { details: TNotification; created_at: string }[]
  }>({
    queryKey: [QUERY_KEYS.GET_NOTIFICATIONS, { ...router.query }],
    queryFn: () => getNotifications({ ...router.query }),
    enabled: !!Cookie.get(ACCESS_TOKEN_NAME)
  })

  return {
    subscriptionsQuery,
    notificationsQuery
  }
}

export const getNotifications = async (params?: any) => {
  try {
    const response: any = await NotificationsService.getNotifications(params)

    return response.data
  } catch (e: any) {
    console.error("Get getNotifications error")
    throw e // Rethrow the error
  }
}

export const readNotifications = async () => {
  try {
    const response: any = await NotificationsService.readNotifications()

    return response.data
  } catch (e: any) {
    console.error("Get getNotifications error")
    throw e // Rethrow the error
  }
}

export const getSubscriptions = async () => {
  try {
    const response: any = await NotificationsService.getSubscriptions()

    const myModifiedCompanyIds = response.data.map((item: any) => item.company_id)

    return myModifiedCompanyIds
  } catch (e: any) {
    console.error("Get Subscription error")
    throw e // Rethrow the error
  }
}

export const postSubscribeCompany = async (company_id: number) => {
  try {
    const response: any = await NotificationsService.postSubscribeCompany({ company_id })

    return response.data
  } catch (e: any) {
    console.error("postSubscribeCompany ERROR")
    throw e // Rethrow the error
  }
}

export const postUnsubscribeCompany = async (company_id: number) => {
  try {
    const response: any = await NotificationsService.postUnsubscribeCompany({ company_id })

    return response.data
  } catch (e: any) {
    console.error("postUnsubscribeCompany ERROR")
    throw e // Rethrow the error
  }
}

export default useNotifications
