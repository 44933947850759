// ** Tailwind Styles
import tw from "tailwind-styled-components"

const DefaultCardContainer = tw.div`
border border-neutral-20 bg-white hover:shadow-card-box hover:border-transparent transition-all duration-500 ease-in-out flex flex-col
`
export const CompanyImage = tw.div`w-12 h-12 relative shrink-0 rounded-full border flex items-center justify-center`

// Category
export const CategoryCardsLayout = tw.div`flex gap-5 mt-20`
export const CategoryCardItem = tw(
  DefaultCardContainer
)`shrink-0 rounded-2xl h-[140px]  w-[190px] p-5 gap-3 cursor-pointer`

// w-[190px]

// Company
export const CompanyCardBox = tw(DefaultCardContainer)`rounded-2xl h-[302px] p-5`
export const CompanyHeader = tw.div`flex items-center justify-between`
export const CompanyBody = tw.div`flex flex-col gap-2 mt-2.5`

// Vacancy
export const StyledVacancyCard = tw(
  DefaultCardContainer
)`relative group rounded-3xl h-[316px] px-5 py-6 transition-all overflow-hidden`
export const VacancyCardHeader = tw.div`flex items-center gap-2 mb-4`
export const VacancyCardHeaderDesc = tw.div`flex flex-col gap-1`
export const VacancyBody = tw.div` flex flex-col gap-2.5 `
export const VacancyCVHoverButton = tw.div`
  rounded-2xl bg-white justify-center px-4 py-5 flex items-center gap-3 w-full h-[92px] 
  absolute left-0 right-0 bottom-0
  transform translate-y-0 opacity-100

  md:transition-all md:duration-300 md:ease-in-out
  md:group-hover:flex md:group-hover:translate-y-0 md:group-hover:opacity-100
  md:translate-y-full md:opacity-0
`

export const CardPin = tw.div`flex items-center gap-1 py-1`
export const CardPinIconContainer = tw.div`w-5 h-5 flex items-center justify-center`
