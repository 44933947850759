import { STATIC_URL, TNET_ACCOUNTS_URL } from "src/env"
import { INaturalPersonPayload } from "src/types/naturalPersonWizardTypes"
import HttpService from "./HttpService"

class RegisterService extends HttpService {
  getWizardCache(req: any) {
    return this.get("/register/natural-person/registered-data", {}, null, req)
  }

  putWizardCache(data: any) {
    return this.put("/register/natural-person/registered-data", data)
  }

  putPersonRegisterFinalFormData(data: INaturalPersonPayload & { photo_version?: number }) {
    const newData = { ...data }
    delete newData?.photo_version
    return this.put("/register/natural-person", newData)
  }

  postImageStaticMyGe(data: FormData) {
    return this.post(STATIC_URL, data)
  }

  postProfilePhoto(data: { path: string }) {
    return this.post(TNET_ACCOUNTS_URL + "/ka/me/photo", data)
  }

  postImageNaturalPerson(data: { path: string }) {
    return this.post("/natural-person/profile-photo", data)
  }

  postAvatar(data: { path: string }) {
    return this.post("/tnet/save-avatar", data)
  }
}

export default new RegisterService()
