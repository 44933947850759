import Image from "next/image"
import React from "react"
import { cx } from "class-variance-authority"
import { useSwiper } from "swiper/react"

interface Props {
  className?: string
  variant: "right" | "left"
}

const ArrowButton = ({ variant, className }: Props) => {
  const icon = variant === "left" ? "/images/keyboard_arrow_left.svg" : "/images/keyboard_arrow_right.svg"
  const svgIcon =
    variant === "left" ? (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="inherit" xmlns="http://www.w3.org/2000/svg">
        <g id="keyboard_arrow_left">
          <path
            id="Vector"
            d="M11.6667 13.2292L8.43339 9.99583L11.6667 6.7625C11.9917 6.4375 11.9917 5.9125 11.6667 5.5875C11.3417 5.2625 10.8167 5.2625 10.4917 5.5875L6.66672 9.4125C6.34172 9.7375 6.34172 10.2625 6.66672 10.5875L10.4917 14.4125C10.8167 14.7375 11.3417 14.7375 11.6667 14.4125C11.9834 14.0875 11.9917 13.5542 11.6667 13.2292Z"
            fill="inherit"
          />
        </g>
      </svg>
    ) : (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="inherit" xmlns="http://www.w3.org/2000/svg">
        <g id="keyboard_arrow_right">
          <path
            id="Vector"
            d="M8.33328 13.2292L11.5666 9.99583L8.33328 6.7625C8.00828 6.4375 8.00828 5.9125 8.33328 5.5875C8.65828 5.2625 9.18328 5.2625 9.50828 5.5875L13.3333 9.4125C13.6583 9.7375 13.6583 10.2625 13.3333 10.5875L9.50828 14.4125C9.18328 14.7375 8.65828 14.7375 8.33328 14.4125C8.01661 14.0875 8.00828 13.5542 8.33328 13.2292Z"
            fill="inherit"
          />
        </g>
      </svg>
    )

  return (
    <button
      className={cx(
        "rounded-full border border-secondary-20 fill-secondary-100 p-1.5 transition-all duration-150 hover:bg-white hover:fill-primary-100 active:border-primary-100",
        className
      )}
    >
      {svgIcon}
    </button>
  )
}

export default ArrowButton
