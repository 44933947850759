import HttpService from "./HttpService"

class UserService extends HttpService {
  getUserData() {
    return this.get("/customer/data")
  }
  getNaturalPerson(req?: any) {
    return this.get("/natural-person", {}, null, req)
  }
  getResume(cv_id?: number) {
    return this.get(`/natural-person/cv/${cv_id}/download`, {}, null, null, "blob")
  }

  uploadResume(data: any) {
    return this.post("/natural-person/cv/upload", data)
  }

  deleteResume() {
    return this.delete("/natural-person/cv")
  }

  setActiveLanguage(data: any) {
    return this.post("/natural-person/active-language", data)
  }

  getActiveLanguage() {
    return this.get("/natural-person/active-language")
  }
}

export default new UserService()
